<template>
  <card titulo="Atos Registrados">
    <template #acoes>
      <a @click.prevent="formulario()" v-if="!isAuditor" class="text-secondary ml-1" href title="Adicionar Ato">
        <i class="fas fa-plus"></i>
      </a>
    </template>

    <div class="div-select-all" v-if="!isAuditor">
      <v-button :button="false" class="icon ml-2" :run="validarLote" :params="[true]" label="Deseja validar os atos selecionados?" :popover="true" position="dropleft" title="Validar Atos">
        <i class="fal fa-ballot"></i>
        <i class="fal fa-check" style="position: absolute;font-size: 10px;bottom: -1px;left: 8.7px;"></i>
      </v-button>
      <v-button :button="false" class="icon ml-2" :run="validarLote" :desabilitado="!podeDesvalidar" :params="[false]" label="Deseja remover validação dos atos selecionados?" :popover="true" position="dropleft" title="Remover validação">
        <i class="fal fa-ballot"></i>
        <i class="fal fa-times" style="position: absolute;font-size: 10px;bottom: -1px;left: 8.7px;"></i>
      </v-button>
      <a @click.prevent="selecionarTodos()" title="Selecionar Todos" class="icon ">
        <i class="ml-2 fal fa-check-circle"></i>
      </a>
      <a @click.prevent="selecionarTodos(true)" title="Limpar Seleção" class="icon  ">
        <i class="ml-2 fal fa-circle"></i>
      </a>
    </div>

    <template v-if="!loading">
      <card-content class="mb-2" v-for="(ato, index) in atos" :key="ato.id">

        <p class="mb-2 small">

          <input style="position: relative;top: 2.5px;left: 0px;" type="checkbox" :id="ato.id" v-if="!isAuditor"
                 v-model="selecionados['ato_' + ato.id]" :value="selecionados['ato_' + ato.id]" :checked="true"/>

          <strong class="icon" @click.prevent="verAto(ato)">
            {{getCodigo(ato, ficha)}}
            <template v-if="ato.titulo">
              - {{ato.titulo}}
            </template>
            <template v-else-if="ato.tipoServico && ato.tipoServico.opcoes && ato.tipoServico.opcoes.titulo">
              - {{ato.tipoServico.opcoes.titulo}}
            </template>
          </strong>

          <template v-if="ato.protocoloCodigo">
            <router-link :to="{ name: 'protocolo', params:{id : ato.protocoloId} }" class="mb-1 pointer" target="_blank" @click.native="$event.stopImmediatePropagation()">
              - {{descricaoProtocolo(ato)}}
            </router-link>
          </template>

          <span class="text-muted" v-if="ato.registro"> - {{$formatters.date(ato.registro)}}</span>

          <template v-if="!isAuditor">

            <v-button :button="false" class="icon ml-2" :run="checarImpressao" :params="[ato, index]"
                      :popover="true" :title="ato.impressao != null ? 'Impresso' : 'Não Impresso'">
              <i :class="{'fal fa-print text-success': ato.impressao != null, 'fal fa-print-slash': !ato.impressao}"></i>
            </v-button>

            <v-button :button="false" class="icon ml-2" :run="validar" :params="[ato]" v-if="!ato.validacao" :popover="true" title="Validar Ato">
              <i class="fal fa-check-double"></i>
            </v-button>

            <v-button :button="false" class="icon ml-2 text-success" :run="validar" label="Deseja remover validação?"
                      :params="[ato]" v-if="ato.validacao && podeDesvalidar" :popover="true" title="Ato Validado">
              <i class="fal fa-check-double"></i>
            </v-button>

            <a title="Editar Ato" @click.prevent="formulario(ato)" class="icon ml-2" v-if="!ato.validacao">
              <i class="fal fa-edit"></i>
            </a>

            <a href @click.prevent="alterarAtoDigital(ato)" class="icon ml-2" v-if="$root.config.geral.permitirAtoDigital" :title="'Digital'">
              <i v-bind:class="['fal fa-file-certificate', {'text-success': ato.digital}]"></i>
            </a>

            <v-button :button="false" :popover="true" class="icon ml-2" v-if="!ato.validacao"
                      title="Excluir Ato" :run="excluir" :params="[ato]">
              <i class="fal fa-trash-alt"></i>
            </v-button>

            <span v-if="ato.selando" class="ml-2">
              <loading-selo tipo="ato" :id="ato.id" :dto="ato" @selado="terminarSelagem(ato, ...arguments)" />
            </span>

            <v-button :button="false" class="icon ml-2" :run="selar" :params="[ato]"
                      v-if="!ato.selando && !ato.selado"  :popover="true" title="Selar Ato">
              <i class="fal fa-stamp"></i>
            </v-button>

            <span class="icon ml-2" v-if="ato.selado && !ato.selando">
                  <i class="fal fa-stamp text-success" title="Selado"></i>
          </span>

            <v-button :button="false" class="icon ml-2" :run="assinar" :params="[ato]" v-if="ato.validacao" :popover="true" title="Assinar Ato">
              <i class="fal fa-pen-nib" :class="{'text-success' : ato.assinado}"></i>
            </v-button>

            <a href @click.prevent="downloadXml(ato)" class=" ml-2" title="Baixar XML" v-if="ato.assinado">
              <i class="fal fa-download  text-success"></i>
            </a>

          </template>

        </p>

        <div v-html="ato.texto" class="texto-minuta mb-2" v-if="ato.texto"></div>
        <div class="texto-minuta mb-5 text-red" v-else>
          Ato sem minuta.
        </div>

        <div v-if="ato.validacao" class="text-success">
          Ato validado em {{ato.validacao | datahora}} por {{ato.validador.nome}} <template v-if="ato.validador.cpf">({{ato.validador.cpf}})</template>
        </div>

        <hr />

      </card-content>
    </template>
    <small-loading v-if="loading"></small-loading>

    <div v-if="!atos.length" class="alert alert-outline-info">
      Não há atos para exibir
    </div>

  </card>
</template>

<script>
  import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness";
  import FrontBusiness from "@/business/FrontBusiness"
  import LacunaBusiness from "@/business/LacunaBusiness.js";
  import AtoBusiness from "@/business/protocolo/AtoBusiness.js";
  import SeloBusiness from "@/business/SeloBusiness.js";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import LoadingSelo from "@/views/Protocolo/LoadingSelo";
  import DetalhesAtoRegistrado from "@/components/IndicadorGeneric/Detalhes/DetalhesAtoRegistrado.vue";
  import DetalhesAto from "@/components/IndicadorReal/Detalhes/DetalhesAto";
  import SmallLoading from "@/components/SmallLoading.vue";

  export default {

    name: "Atos",
    components: {SmallLoading, CardContent, Card, LoadingSelo},
    props: {
      ficha: Object
    },

    data() {
      return {
        atos: [],
        loading: true,
        selecionados: {},
      }
    },

    async mounted() {
        await this.load();
    },

    computed: {
      usuario() {
        return this.$root.usuarioLogado;
      },
      podeDesvalidar(){
        return this.$root.getPermission({id: 'PERMITIR_REMOVER_VALIDACAO_ATOS_INDICADORES_RI'})
      },

      isAuditor() {
        return this.$root.usuarioLogado?.auditor;
      }

    },
    methods: {
      getCodigo: AtoBusiness.getCodigo,

      async load(){
        this.loading = false;
        this.atos = await RegistroAuxiliarBusiness.buscarAtosRegistrados(this.ficha.id);
        this.loading = false;
        await this.initValues();
      },

      async initValues() {
        this.loading = true;
        this.$set(this, 'selecionados', {});
        this.atos.forEach((ato) => {
          this.$set(this.selecionados, 'ato_' + ato.id, false);
        })
        this.loading = false;
      },

      async formulario(ato = {}) {
          await FrontBusiness.openModal(DetalhesAto, {id: ato.id, protocoloId: ato.protocoloId, ficha: this.ficha});
          await this.load();
      },

      async alterarAtoDigital(ato) {
        this.loading = true;
        await AtoBusiness.tratarAlteracaoAtoDigital(ato, this.atos);
        this.loading = false;
        this.$emit('load');
      },

      async checarImpressao(ato, index, fichaId) {
        let imprimindo = !fichaId ? !ato.impressao : true;
        await AtoBusiness.imprimir(ato.id, imprimindo);
        await this.load();
        this.$emit('load')
      },

      async validar(ato, load = true) {
        let descricao = this.getCodigo(ato);
        if (ato.tipoServico && ato.tipoServico.opcoes && ato.tipoServico.opcoes.titulo) descricao += ` - ${ato.tipoServico.opcoes.titulo}`;
        if (ato.protocoloCodigo) descricao += ` - Protocolo ${ato.protocoloCodigo}`;

        if (ato.validacao) {
          await AtoBusiness.desvalidar(ato.id, {descricao: descricao});
        } else {
          await AtoBusiness.validar(ato.id, {descricao: descricao}).catch(e => {
            FrontBusiness.showError('', e.message);
          });
        }
        await this.load();
        this.$emit('load', false)
      },

      async validarLote(validar) {
        let selecionado = Object.keys(this.selecionados).find(key => this.selecionados[key] === true);
        let ids = [];
        let erro = false;
        if (selecionado) {
          for (let d in this.selecionados) {
            if (this.selecionados[d]) {
              let id = d.split('_')[1];
              let ato = this.atos.find(a => a.id === id);
              if ((!validar && ato.validacao) || (validar && !ato.validacao)) {
                ids.push(id);
              }
            }
          }
          if (!validar) {
            await AtoBusiness.desvalidarLote(ids);
          }
          if (validar) {
            await AtoBusiness.validarLote(ids).catch(e => {
              FrontBusiness.showError('', e.message)
              erro = true;
            });
          }
          if (!erro) {
            await this.load();
            this.$emit('load')
            await this.initValues();
            FrontBusiness.showSuccess('', `${validar ? 'Atos validados' : 'Removida a validação'} com sucesso`);
          }
        } else {
          FrontBusiness.showError('Atenção!', 'Nenhum ato selecionado', 'app', 5000);
        }
      },

      async downloadXml(ato){
        FrontBusiness.downloadUrl(`${window.server.API}/api/lacuna/ato/${ato.id}/download`, 'ato-assinado.xml');
      },

      async assinar(ato, load = true) {
        if(load){
          await LacunaBusiness.preauthorizeSignatures(await LacunaBusiness.getThumb()).catch(e => {
            FrontBusiness.showError('', 'Erro ao tentar utilizar o certificado digital. Realize seu login novamente.');
            return false;
          });
        }

        let xml = await LacunaBusiness.sign('ato', ato.id, '', null, false).catch(e => {
          FrontBusiness.showError(`Erro na assinatura do ato ${ato.codigo}`, e.message || e);
          return false;
        });

        if(xml && load){
          FrontBusiness.showSuccess('', 'Ato assinado com sucesso');
          await this.load();
          this.$emit('load');
        }

      },

      terminarSelagem(ato, result) {
        this.$set(ato, 'selando', result.selando);
        this.$set(ato, 'selado', result.selado);
      },

      verAto(ato) {
        if(!this.isAuditor){
          FrontBusiness.openModal(DetalhesAtoRegistrado, {item: ato, ficha: this.ficha});
        }
      },

      descricaoProtocolo(ato) {
          return FrontBusiness.nomearProtocolo(ato.protocoloDominio, ato.protocoloTipo, ato.protocoloCodigo);
      },

      selecionarTodos(limpar) {
        for (let a in this.selecionados) {
          this.$set(this.selecionados, a, !limpar ? true : false)
        }
      },

      async excluir(ato){
        let verificacao = await AtoBusiness.excluirAtos([ato], true, false);
        if (verificacao && verificacao.length && verificacao[0]) {
          await this.load();
          this.$emit('load')
          FrontBusiness.showSuccess('', 'Ato excluído com sucesso');
        }
      },

      async selar(ato) {
        // if(ato.selado){
          await SeloBusiness.selarAto(ato.id);
        // }
        await this.load();
        this.$emit('load');
      },

    }
  }
</script>
